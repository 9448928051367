@import url(//netdna.bootstrapcdn.com/font-awesome/3.0/css/font-awesome.css);
body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

/******************************

General

*****************************/
.style_common_color__2Zqw_ {
    color: #0a1612;
    color: #f3d250;
    color: #BAC0CF;
    color: #6fd1cc;
    color: #dddddd;
    color: #ffccbc;
    box-shadow: 1px 2px 2px #9292925b;
    box-shadow: 5px 5px 25px #312f2f5b;
}


* {
    margin: 0;
    padding: 0;
    box-sizing: border-box; 
    -webkit-box-sizing: border-box;
    transition: all ease .25s;
}

.style_body_wrapper__2cOZj {
    font-family: 'Economica', sans-serif;
    color: #0a1612;
    font-size: 18px;
    position: relative;
}
.style_wrapper__-zIuW {
    width: 90%;
    max-width: 1520px;
    margin: auto;
}
.style_flex__3zTcB {
    display: flex;
    justify-content: center;
    align-items: center;
}
.style_flex_container__2Jyg2 {
    display: flex;
    justify-content: center;
    align-items: center;
}
.style_flex_wrap___WkO5 {
    display: -webkit-flex; /* Safari */
    -webkit-flex-wrap: wrap; /* Safari 6.1+ */
    display: flex;   
    flex-wrap: wrap;
}
.style_body_wrapper__2cOZj a {
    color: #0a1612;
    text-decoration: none;
}
.style_active__2cos1, .style_active__2cos1:hover {
    color: #0a1612 !important;
    font-weight: bold;
}

.style_body_wrapper__2cOZj li {
    list-style: none;
    margin-left: 30px;
}
.style_body_wrapper__2cOZj ul.style_list_style_initial__iSfQ9 li {
    list-style-type: initial;
}
.style_body_wrapper__2cOZj img {
    max-width: 100%;
}
.style_clearfix__3RSgF:after {
    display: block;
    content: '';
    clear:both;
}
.style_hidden__LLZq5 {
    display: none;
}
.style_btn__1aWwW,
a.style_btn__1aWwW{
    background: #6fd1cc;
    border: 1px solid #6fd1cc; 
    box-shadow: 1px 2px 2px rgba(49,47,47,.36);
    color: #fff;
    padding: .5em 1.5em;
    cursor: pointer;
    font-weight: bold;
}
.style_btn__1aWwW:hover,
a.style_btn__1aWwW:hover {
    background: #fff;
    border: 1px solid #fff; 
    box-shadow: 1px 2px 2px #9292925b;
    color: #6fd1cc;
    font-weight: bold;
}
.style_btn_sm__ZyP6A,
a.style_btn_sm__ZyP6A{
    padding: 0px 7px;
}
.style_fa-2x__n31Rm {
    font-size: 1.8em !important;
    margin-top: .1em !important;
}
.style_clear-fix__3sqcb {
    clear: both;
    height: 1px;
}
.style_color_link__3xSeW{
    text-decoration: underline !important;
    color: #005892 !important;
}




/********************************************

				ANIMATIONS

********************************************/


@keyframes style_fadein__1vZre{
	0%{
		opacity: 0;
		}
	100%{
		opacity: 1;
		}
}

.style_keyframes_fadein__2Fsv1{
    animation: style_fadein__1vZre 2s forwards 0s ease;
}


@keyframes style_slidein__1h7EU{
	0%{
        transform: translateX(-25%);
		opacity: 0;
		}
	100%{
        transform: rotate(xx) translateX(0);
		opacity: 1;
		}
}

.style_keyframes_slidein__VcyBU{
    animation: style_slidein__1h7EU 1s forwards 0s ease;
}

/* Modal */
.style_modal__1qDPJ{
    position: fixed;
    top: 0;
    z-index: 9999;
    width: 100%;
    height: 100vh;
    display: flex;
    justify-content: center;
    align-items: center;
    background: rgba(16, 15, 15, 0.76);
    transition: all .5s;
}
.style_modal_content__23jmg {
    position: relative;
    padding: 2em 3em;
    border-radius: 2px;
    background: #fff;
    min-height: 180px;
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 25px;
    width: 100%;
    max-width: 500px;
    text-align: center;
}
.style_modal_close__sRkpn{
    position: absolute;
    top: 0px;
    right: 5px;
    cursor: pointer;
}


/******************************

 Header - Navigation
 
******************************/

.style_navigation__2ZDkZ {
    width: 100%;
    position: absolute;
    top: 0;
}
.style_navigation__2ZDkZ .style_brand__kLxlR {
    width: 30%;
    height: 100px;
    display: flex;
    justify-content: flex-start;
    align-items: center;
}
.style_navigation__2ZDkZ .style_brand__kLxlR h1 {
    font-size: 2rem;
}
.style_navigation__2ZDkZ .style_nav__3bPTh {
    width: 70%;
    height: 100px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-end;
}
.style_navigation__2ZDkZ .style_nav__3bPTh ul li {
    display: inline-block;
    padding: 4px .7rem;
}
.style_navigation__2ZDkZ .style_nav__3bPTh ul li a {
    text-decoration: none;
    color: #BAC0CF;
}
.style_navigation__2ZDkZ .style_nav__3bPTh ul.style_social__9fOtq li a {
    color: #0a1612;
}
.style_navigation__2ZDkZ .style_nav__3bPTh ul li a:hover {
    border-bottom: 2px solid #0a1612;
    color: #0a1612;
}    

.style_navigation_mobile__1vLnm{
    display: none;
}


/******************************

 Footer 
 
 ******************************/

.style_footer__3assW {
    position: absolute;
    bottom: 0;
    height: 50px;
    width: 100%;
}




/******************************

Main

******************************/

.style_main__2BGuL {
    display: flex;
    flex: 1 1;
    align-items: center;
}
.style_main__2BGuL .style_content__1S_kF {
    height: 100vh;
    display: flex;
    align-items:center;
    justify-content: flex-start;
    width: 95%;
}
.style_main__2BGuL .style_header_nav__2DodK a:hover .style_profile__2Y4ml{
    display: none;
}
.style_main__2BGuL .style_header_nav__2DodK h1 {
    font-size: 3em;
}
.style_main__2BGuL .style_header_nav__2DodK a{
    color: #BAC0CF;
    cursor: pointer;
}
.style_main__2BGuL .style_header_nav__2DodK a:hover{
    text-decoration: underline;
    color: #BAC0CF;
}
.style_main__2BGuL .style_header_nav__2DodK a.style_active__2cos1{
    color: #0a1612;
}
.style_main__2BGuL .style_h1_mobile__2gFYv {
    display: none;
}



/******************************
******************************/

.style_main__2BGuL .style_box__2lWE6 {
    position: absolute;
    right: 0;
    width: 50%;
    height: 65%;
}
.style_main__2BGuL .style_info__3eC00 {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    opacity: 0;
    background: #f3d250;
}
.style_main__2BGuL .style_info__3eC00>div {
    padding: .5em 3em;
    position: relative;
}
.style_main__2BGuL .style_info__3eC00 .style_info_img__3FPo7{
    position: absolute;
    right: 35px;
    top: -15px;
}
.style_main__2BGuL .style_info__3eC00 .style_info_img__3FPo7 img{
    height: 125px;
}
.style_main__2BGuL .style_info__3eC00 .style_headline__3uNOl {
    font-size: 3rem;
}
.style_main__2BGuL .style_info__3eC00 .style_copy__24w5y p{
    padding-top: 15px;
    line-height: 1.5;
    letter-spacing: .5px;
    font-size: 1rem;
}

/******************************
******************************/
.style_main__2BGuL .style_profile_box__3e76k {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    background: #6fd1cc;
}
.style_main__2BGuL .style_profile__2Y4ml {
    width: 70%;
    background-image: url(/static/media/profile.d868e356.webp);
    background-size: cover;
    height: 90%;
    background-position: center bottom;
    background-repeat: no-repeat;
}
.style_main__2BGuL .style_profile_mobile__23Nrg {
    display: none;
}


/******************************
******************************/
.style_main__2BGuL .style_experiences_box__3bmse {
    background: #dddddd;
    opacity: 0;
    display: flex;
    justify-content: center;
    align-items: center;
}
.style_timeline__Y_nzJ {
    position: relative;
}
.style_timeline__Y_nzJ::before {
    content: '';
    position: absolute;
    top: 5px;
    left: 33%;
    bottom: 40px;
    width: 2px;
    background: #0a1612;
}
.style_entry__2c5J- {
    clear: both;
    text-align: left;
    position: relative;
}
.style_entry__2c5J-  .style_title__3ih42 {
    margin-bottom: .5em;
    float: left;
    width: 33%;
    padding-right: 30px;
    text-align: right;
    position: relative;
}
.style_entry__2c5J-  .style_title__3ih42:before {
    content: '';
    position: absolute;
    width: 10px;
    height: 10px;
    border: 1px solid #0a1612;
    background-color:#0a1612;
    border-radius:100%;
    top: 15%;
    right: -7px;
}
.style_entry__2c5J-  .style_title__3ih42 h3 {
    margin: 0;
    font-size: 1rem;
}
.style_entry__2c5J-  .style_title__3ih42 p {
    margin: 0;
    font-size: 0.8rem;
}
.style_entry__2c5J-  .style_title__3ih42 img {
    width: 100%;
    max-width: 80px;
    margin-top: 5px;
}

.style_body__3bF37 {
    margin: 0 0 1em;
    float: left;
    width: 65%;
    padding-left: 30px;
}
.style_body__3bF37 p {
    line-height: 1.4em;
}
.style_body__3bF37 p:first-child {
    margin-top: 0;
    font-weight: 400;
}
.style_body__3bF37 ul {
    color:#aaa;
    padding-left: 0;
    list-style-type: none;
}
.style_body__3bF37 ul li:before {
    content: "–";
    margin-right: .5em;
}




/******************************
******************************/
.style_main__2BGuL .style_skills_box__KKuhz {
    background: #ffccbc;
    opacity: 0;
    display: flex;
    justify-content: center;
    align-items: center;
}
.style_main__2BGuL .style_skills_box__KKuhz > div {
    width: 50%;
    display: flex;
    flex-direction: column;
    justify-content: center;
}
.style_skill__UqBjO {
    margin: 7px 0;
}
.style_skill__UqBjO h3 {
    font-size: 1.1rem;
    color: #0a1612;
    font-weight: 600;
}
.style_skill__UqBjO p {
    font-size: .8rem;
    margin: 3px 0;
}
.style_bar__1hDgP {
    width: 100%;
}
.style_bar__1hDgP .style_black__2EXjN {
    height: 10px;
    background-color: #0a1612;
    border: 1px solid #0a1612;
    float: left;
    border-top-left-radius: 5px;
    border-bottom-left-radius: 5px;
}
.style_bar__1hDgP .style_white__2wIpn {
    height: 10px;
    border: 1px solid #0a1612;
    float: left;
    border-top-right-radius: 5px;
    border-bottom-right-radius: 5px;
}
.style_front_end__26aak .style_black__2EXjN{
    width: 90%;
}
.style_font_end__1UYAY .style_black__2EXjN::before {
    content: "";
    position: absolute;
    position: absolute;
    width: 10px;
    height: 10px;
    border: 1px solid #0a1612;
    background-color:#0a1612;
    border-radius:100%;
    top: 15%;
    right: -7px;
}
.style_front_end__26aak .style_white__2wIpn{
    width: 10%;
}

.style_back_end__1U95X .style_black__2EXjN{
    width: 85%;
}

.style_back_end__1U95X .style_white__2wIpn{
    width: 15%;
}

.style_database__3FLdW .style_black__2EXjN{
    width: 87%;
}

.style_database__3FLdW .style_white__2wIpn{
    width: 13%;
}

.style_wordpress__39iqi .style_black__2EXjN{
    width: 75%;
}

.style_wordpress__39iqi .style_white__2wIpn{
    width: 25%;
}



/******************************
******************************/

.style_main__2BGuL .style_box_2__1OrSZ {
    opacity: 0;
    background-image: url(/static/media/allpancakes.724acb17.webp);
    background-size: cover;
    background-position: center;
}
.style_main__2BGuL .style_box_1__1VB4f {
    opacity: 0;
    background-image: url(/static/media/prg.e2b719dc.webp);
    background-size: cover;
    background-position: center;
}
.style_main__2BGuL .style_box_3__2hQ62 {
    opacity: 0;
    background-image: url(/static/media/infrashares.7aa3c0c1.webp);
    background-size: cover;
    background-position: center;
}
.style_main__2BGuL .style_box_4__1X9BE {
    opacity: 0;
    background-image: url(/static/media/gsl.83647b83.webp);
    background-size: cover;
    background-position: center;
}
.style_main__2BGuL .style_box_5__2FQ98 {
    opacity: 1;
    background-image: url(/static/media/bamboocopter.13df245f.webp);
    background-size: cover;
    background-position: center;
}
.style_main__2BGuL .style_box_6__3u9Nv {
    opacity: 0;
    background-image: url(/static/media/dossier.c49febd9.webp);
    background-size: cover;
    background-position: center;
}
.style_main__2BGuL .style_box__2lWE6{
    /* position: relative; */
    border: 1px solid #BAC0CF;
}
.style_main__2BGuL .style_techs__2x9Q5{
    position: absolute;
    left: -50px;
    width: 50px;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    align-items: flex-end;
}
.style_main__2BGuL .style_techs__2x9Q5 .style_item__17jR_{
    border: 2px solid #BAC0CF;
    border-right: none;
    border-top-left-radius: 5px;
    border-bottom-left-radius: 5px;
    padding: 5px;
    margin: 5px;
    margin-right: 0;
}
.style_main__2BGuL .style_techs__2x9Q5 .style_item__17jR_.style_view_site__h7UEJ:hover{
    background-color: #6fd1cc;
    border: 2px solid #6fd1cc;
    border-right: none;
}
.style_main__2BGuL .style_techs__2x9Q5 .style_item__17jR_.style_view_site__h7UEJ:hover a{
    color: #fff !important;
}



/******************************

Contact

******************************/

.style_form__12Hrb {
    display: flex;
    justify-content: center;
    align-items: center;
    background: #dddddd;
}
.style_card__3L553 {
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
    width: 50%;
}
.style_row__1oBEl {
    width: 100%;
    margin: 15px auto;
    display: flex;
    justify-content: center;
}
input, textarea {
    width: 100%;
    border: none;
    background-color: #fff;
    padding: 1.3em;
    border-radius: 3px;
    font-size: 16px;
}
input::placeholder, textarea::placeholder {
    color: #0a1612;
    padding-left: 10px;
    font-family: 'Economica', sans-serif;
    font-size: 16px;;
}
.style_btn_send__17OLy {
    font-size: 1em;
}
.style_contact__3s73r .style_info__3eC00 .style_headline__3uNOl {
    color: #0a1612;
    font-weight: bold;
    font-size: 2.2rem;
}




    
/********************************************

				COMMON CONTENT

********************************************/

.style_common_content__1tky2 {
    height: 100%;
    padding: 50px 0;
}
.style_common_content__1tky2 h1 {
    /* color: #49a6d4; */
    font-size: 2rem;
}
.style_common_content__1tky2 .style_sub_headline__31NgG {
    padding: 15px 0;
}
.style_common_content__1tky2 .style_copy__24w5y {
    margin: 25px 0;
}
.style_common_content__1tky2 .style_copy__24w5y p{
    font-size: 1rem;
    margin: .5em auto;
}






/********************************************

            COMMON HERO

********************************************/


.style_common_hero__13uhn .style_hero_image__3VCyv {
    height: 500px;
    width: 100%;
    z-index: -1;
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
}
.style_common_hero__13uhn .style_hero_image__3VCyv.style_full_height__20KU2 {
    height: 100vh;
}
.style_common_hero__13uhn .style_overlay__14yLa {
    /* background-color: rgba(0, 0, 0, 0.5); */
    height: 100%;
}
.style_common_hero__13uhn .style_content__1S_kF {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100%;
    z-index: 10;
    color: #fff;
}
.style_common_hero__13uhn .style_content__1S_kF .style_copy__24w5y {
    color: #fff;
    text-align: center;
    font-family: "", sans-serif;
    font-style: normal;
}
.style_common_hero__13uhn .style_container__3z08n .style_copy__24w5y .style_title__3ih42 {
    font-size: 4rem;
    font-weight: 600;
    padding: 25px;
    text-transform: uppercase;
}
.style_common_hero__13uhn .style_container__3z08n .style_copy__24w5y .style_subtitle__1oLvx {
    font-size: 2rem;
}







/********************************************

				Image Box

********************************************/

.style_image_box__3PHYX {
	width: 33.33%;
	padding: 1em 3em;
	height: auto;
}
.style_image_box__3PHYX .style_flex_container__2Jyg2 {
	flex-direction: column;
}
.style_image_box__3PHYX img {
	height: auto;
}
.style_image_box__3PHYX p {
	text-align: right;
	width: 100%;
	margin: 5px auto;
}
.style_image_box__3PHYX .style_flex_row__2NPDm {
	flex-direction: row;
}





.style_image_box_1s__3Dmqv {
	width: 100%;
	padding: 3em;
	height: auto;
}
.style_image_box_1s__3Dmqv .style_flex_container__2Jyg2 {
	width: 70%;
}


.style_image_box_2s__3bvVV {
	width: 100%;
	padding: 3em;
	height: auto;
}
.style_image_box_2s__3bvVV .style_flex_container__2Jyg2 {
	width: 90%;
}
.style_image_box_2s__3bvVV .style_flex_container__2Jyg2 >div {
	padding: 1em;
}







.style_image_box_with_copy__cqnI0 {
	margin: 3em 0;
}
.style_image_box_with_copy__cqnI0 .style_image__21yo8 {
	width: 40%;
	min-width: 400px;
}
.style_image_box_with_copy__cqnI0 .style_copy__24w5y {
	width: 60%;
	max-width: 500px;
    margin: 0 100px;
    padding: 2em 0em;
    border-bottom: 3px solid #ddd;
}

.style_image_box_with_copy__cqnI0 .style_copy__24w5y h1{
	padding: 10px 0;
}
.style_image_box_with_copy__cqnI0 p {
	width: 100%;
	margin: 5px auto;
}
.style_image_box_with_copy__cqnI0 .style_flex_row__2NPDm {
	flex-direction: row;
}




.style_bamboocopter_home__fL53e {
    padding: 7em 0;
}







/* ********************************************

Responsive CSS 


*********************************************/

@media (max-width: 992px){
}


@media (max-width: 769px){
    .style_flex__3zTcB {
        flex-direction: column;
    }
    .style_wrapper__-zIuW {
        width: 100%;
    }
    .style_main__2BGuL .style_content__1S_kF {
        flex-direction: column;
        justify-content: space-evenly;
        margin: auto;
        width: 100%;
        height: auto;
        align-items: flex-start;
    }
    .style_main__2BGuL .style_box__2lWE6 {
        position: initial;
        width: 100%;
        height: 100%;
    }

    .style_main__2BGuL .style_header_nav__2DodK {
        margin-top: 4em;
        margin-left: 1em;
    }
    .style_main__2BGuL .style_h1_desktop__23Vbi{
        display: none;
    }
    /* .main .h1_mobile {
        display: block;
    } */
    .style_main__2BGuL .style_profile_box__3e76k {
        display: none;
    }
    .style_main__2BGuL .style_profile_mobile__23Nrg {
        display: block;
        margin: 1em 0;
    }
    .style_main__2BGuL .style_profile_mobile__23Nrg img {
        width: 100%;
    }
    .style_main__2BGuL .style_info__3eC00,
    .style_main__2BGuL .style_skills_box__KKuhz,
    .style_main__2BGuL .style_experiences_box__3bmse {
        display: block;
        opacity: 1 !important;
        margin-bottom: 1em;
    }
    .style_main__2BGuL .style_info__3eC00,
    .style_main__2BGuL .style_skills_box__KKuhz {
        background: #fff;
    }
    
    .style_main__2BGuL .style_experiences_box__3bmse{
        background: #f5f5f5;
    }


    .style_main__2BGuL .style_info__3eC00 div {
        padding: 1em;
    }
    .style_main__2BGuL .style_info__3eC00 .style_info_img__3FPo7{
       display: none;
    }
    .style_main__2BGuL .style_info__3eC00 .style_headline__3uNOl {
        font-size: 2rem;
    }
    .style_main__2BGuL .style_skills_box__KKuhz > div {
        width: 80%;
        margin: 5em auto;
        margin-top: 4em;
    }

    .style_timeline__Y_nzJ {
        padding: 2em 5px;
    }
    .style_timeline__Y_nzJ::before {
        top: 40px;
        bottom: 15px;
        background: #0a1612;
        left: calc(33% + 1.5px);
    }


    .style_navigation__2ZDkZ {
        display: none;
    }
    .style_navigation_mobile__1vLnm {
        width: 100%;
        display: block;
        z-index: 2;
        background-color: #fff;
        position: fixed;
    }
    .style_navigation_mobile__1vLnm .style_brand__kLxlR {
        width: 100%;
        height: 50px;
        display: flex;
        justify-content: flex-start;
        align-items: center;
    }
    .style_navigation_mobile__1vLnm .style_brand__kLxlR h1 {
        font-size: 1.5rem;
    }
    .style_navigation_mobile__1vLnm .style_brand__kLxlR h1 a{
        color: #0a1612;
        margin-left: 10px;
    }
    .style_navigation_mobile__1vLnm .style_nav_icon__2NrUV {
        position: absolute;
        right: 15px;
        top: 7px;
        color: #0a1612;
    }
    .style_navigation_mobile__1vLnm .style_nav__3bPTh {
        width: 100%;
        height: 100vh;
        display: flex;
        flex-direction: column;
        position: absolute;
        background-color: #fff;
        left:-900px;
    }
    .style_navigation_mobile__1vLnm .style_nav__3bPTh ul {
        display: flex;
        flex-direction: column;
    }
    .style_navigation_mobile__1vLnm .style_nav__3bPTh ul.style_social__9fOtq {
        flex-direction: row;
        justify-content: space-around;
        color: #0a1612;
    }

    .style_navigation_mobile__1vLnm .style_nav__3bPTh ul li {
        display: inline-block;
        padding: 1em;
        font-size: 1.3em;    
    }
    .style_navigation_mobile__1vLnm .style_nav__3bPTh ul li a {
        text-decoration: none;
        color: #0a1612;
    }
    .style_navigation_mobile__1vLnm .style_nav__3bPTh ul li a:hover {
        border-bottom: none;
    }  
    .style_navigation_mobile__1vLnm .style_nav__3bPTh ul li a.style_active__2cos1 {
        color: #0a1612;
        text-decoration: underline;
    }
    .style_navigation_mobile__1vLnm .style_nav__3bPTh.style_animated__20KZU {
        left: 0;
        z-index: 99;
    }



    .style_entry__2c5J- .style_title__3ih42 h3 {
        margin: 0;
        font-size: .8rem;
    }

    .style_main__2BGuL .style_techs__2x9Q5 {
        left: auto;
        right: 0;
        height: auto;
    }
    .style_main__2BGuL .style_techs__2x9Q5 .style_item__17jR_ {
        padding: 1px;
        margin: 3px 0 3px 3px;
        background: #fff;
        box-shadow: 3px 3px 10px #000;
    }
    .style_main__2BGuL .style_techs__2x9Q5 .style_item__17jR_.style_item__17jR_.style_view_site__h7UEJ {
        background: #6fd1cc;
    }
    .style_main__2BGuL .style_techs__2x9Q5 .style_item__17jR_.style_item__17jR_.style_view_site__h7UEJ a{
        color: #fff;
    }


    /* Contact */

    .style_contact__3s73r .style_header_nav__2DodK h1 {
        font-size: 1.8em;
        margin: 1em auto;
    }
    .style_contact__3s73r .style_copy__24w5y {
        margin: 1em auto;
    }
    .style_form__12Hrb {
        background: #fff;
    }
    .style_card__3L553 {
        width: 95%;
    }
    input, textarea {
        background-color: #f5f5f5;
    }

    .style_footer__3assW {
        position: static;
    }



}






/********************************************

        IMAGE BOX

********************************************/
@media only screen and (max-width: 992px) {

    .style_image_box_with_copy__cqnI0 .style_image__21yo8 {
        min-width: 300px;
    }
    .style_image_box_with_copy__cqnI0 .style_copy__24w5y {
        width: 60%;
        max-width: 600px;
        margin: 0 35px;
        padding: 2em 0em;
    }
}


@media only screen and (max-width: 769px) {
    .style_flex_container__2Jyg2 {
        flex-direction: column;
    }
    .style_image_box__3PHYX,
    .style_image_box_with_copy__cqnI0 {
        width: 100%;
        padding: 1em;
        margin: 1em 0;
    }
    .style_image_box_1s__3Dmqv,
    .style_image_box_2s__3bvVV {
        width: 100%;
        padding: 0;
        margin: 0;
    }
    .style_image_box_1s__3Dmqv .style_flex_container__2Jyg2,
    .style_image_box_2s__3bvVV .style_flex_container__2Jyg2 {
        width: 100%;
        padding: 0;
        margin: 0;
    }
    .style_image_box_1s__3Dmqv .style_flex_container__2Jyg2>div,
    .style_image_box_2s__3bvVV .style_flex_container__2Jyg2>div {
        padding: 1em 0 0;
        margin: 0;
    }

    .style_image_box_with_copy__cqnI0 .style_image__21yo8 {
        min-width: 100%;
        width: 100%;
    }
    .style_image_box_with_copy__cqnI0 .style_copy__24w5y {
        width: 100%;
        max-width: 100%;
        margin: 0 35px;
        padding: 1em 0em;
    }


    .style_portfolio__1WH4X .style_box__2lWE6 {
        position: static;
        width: 100%;
        height: 235px;
        margin: 0;
    }
    .style_portfolio__1WH4X .style_box_1__1VB4f,
    .style_portfolio__1WH4X .style_box_2__1OrSZ,
    .style_portfolio__1WH4X .style_box_3__2hQ62,
    .style_portfolio__1WH4X .style_box_4__1X9BE,
    .style_portfolio__1WH4X .style_box_5__2FQ98,
    .style_portfolio__1WH4X .style_box_6__3u9Nv {
        opacity: 1 !important;
    }

}

/********************************************

GENERAL 

********************************************/ /* this is need for input search glass icon */
	*{
		margin: 0;
		padding: 0;
		box-sizing: border-box;
		-webkit-box-sizing: border-box;
		transition: all .25s;
	}
	.allpancakes_style_body_wrapper__2o7rf, .allpancakes_style_body_wrapper__2o7rf input {
        overflow-x:hidden;
		font-family: "",sans-serif; /* TODO: update new fonts */
		font-style: normal;
		font-weight: 100;
		color: #707070; /* TODO: update new color */
		-moz-osx-font-smoothing: grayscale;
		background: #fff;
		font-size: 16px;
		line-height: 1.5rem;
		letter-spacing: 0;
		vertical-align: baseline;
	}
    .allpancakes_style_common_color__IbVIo {
        color: #49a6d4;
        color: #707070;
		color: #f5f5f5;
		color: #9FC4CE;
    }
	.allpancakes_style_body_wrapper__2o7rf a {
		text-decoration: none;
		color: inherit;
		cursor: pointer;
	}
	
    .allpancakes_style_body_wrapper__2o7rf li {
		list-style: none;
		margin-left: 30px;
	}
	.allpancakes_style_body_wrapper__2o7rf ul.allpancakes_style_list_style_initial__7_Poj li {
		list-style-type: initial;
	}
	.allpancakes_style_body_wrapper__2o7rf img {
		max-width: 100%;
	}
	.allpancakes_style_clearfix__3PNoj:after {
		display: block;
		content: '';
		clear:both;
	}
	.allpancakes_style_wrapper__SmGsH {
		width: 100%;
	}
	.allpancakes_style_container__2DXM2 {
		width: 90%;
		margin: auto;
        max-width: 1240px;
	}
	.allpancakes_style_flex__3uqdc {
		display: flex;
    }
	.allpancakes_style_flex_container__hZnpF {
		display: flex;
		justify-content: center;
		align-items: center;
	}
	.allpancakes_style_flex_wrap__3DWUC {
		display: -webkit-flex; /* Safari */
		-webkit-flex-wrap: wrap; /* Safari 6.1+ */
		display: flex;   
		flex-wrap: wrap;
	}
    .allpancakes_style_relative__1E4jN {
        position: relative;
    }
    .allpancakes_style_absolute___x2Z2 {
        position: absolute;
    }
	.allpancakes_style_hidden__3i3Fg {
        visibility: hidden;
		opacity: 0;
		transition: visibility 0s, opacity .25s ease;
	}
    .allpancakes_style_display_none__2B6Vz {
        display: none;
    }
	.allpancakes_style_bg_light_grey__e1drL {
        background-color: #f5f5f5;
	}
	.allpancakes_style_bold_600__22bSL{
        font-weight: 600;
    }
    .allpancakes_style_bold_800__QXaIw {
        font-weight: 800;
    }
    .allpancakes_style_bold__2yMno {
        font-weight: bold;
    }
	.allpancakes_style_text_align_center__2BgLL {
        text-align: center;
    }
    .allpancakes_style_text_align_left__2TxLj{
        text-align: left;
    }
    .allpancakes_style_text_align_right__1Y8UI{
        text-align: right;
    }
    .allpancakes_style_m_auto__2EUfB{
        margin: auto;
    }
    
	.allpancakes_style_recaptcha_notice__3wRXo {
        /* font-size: .7rem; */
	}
	.allpancakes_style_grecaptcha-badge__uBxxG {
        /* display: none ; */
	}
    
    
    
    
    
    

/*************************


		TABLE 


*************************/
	.allpancakes_style_table-responsive__16eRt{
		overflow-x: auto;
	}

	table {
		border-collapse: collapse;
		width: 75%;
		border: none;
		margin: 25px auto;
	}
	th, td {
		text-align: left;
		color: #707070;
		/* border: 1px solid #49a6d4; */
		text-align: center; 
		vertical-align: middle;
		width: 150px;
		height: 115px;
		word-wrap: normal;
		padding: 30px;
		font-weight: 600;
	}
	th{
		/* color: #49a6d4; */
		text-transform: uppercase;
	}
	th:last-child, td:last-child {
		border-right: none;
	}
	tr:first-child th {
		border-top: none;
	}
	tr:last-child td {
		border-bottom: none;
	}
	.allpancakes_style_table-head__q8P75 { /* custom table head: create a span table head. */
		/* color: white; */
		text-align: center;
		background-color: #f5f5f5; /* TODO: update color */
		height: 35px;
		padding: 10px
    }
    




     
/*************************


		MODAL 


*************************/

	/***** Normal Form *****/
	
	.allpancakes_style_modal__1b9jy {
		display: none;
		position: fixed;
		bottom: 0;
		width: 100%;
		height: 100%;
		color: white;
		background-color: rgba(146, 146, 146, 0.384);
		z-index: 100;
		box-sizing: border-box;
		padding: 15px 0;
		overflow-y: auto;
		overflow-x: hidden;
	}

	.allpancakes_style_modal__1b9jy.allpancakes_style_flex__3uqdc {
		justify-content: center;
		align-items: center;
	}
	.allpancakes_style_modal__1b9jy .allpancakes_style_modal_content__n2rtj {
		background-color: #fff;
		padding: 5em;
		color: #49a6d4;
		position: relative;
		text-align: center;
		font-size: 1.2rem;
	}
	.allpancakes_style_modal__1b9jy .allpancakes_style_modal_content__n2rtj h2 {
		font-size: 1.7rem;
		padding-bottom: .5em;
	}
	.allpancakes_style_modal__1b9jy .allpancakes_style_modal_content__n2rtj h4{
		font-size: 1.2rem;
	}
	.allpancakes_style_modal__1b9jy .allpancakes_style_btn_modal_close__1Egs3 {
		position: absolute;
		top: 5px;
		right: 12px;
		font-size: 1.5rem;
		font-weight: 600;
		color: #49a6d4;
		cursor: pointer;
	}





/*************************


		BUTTONS 


*************************/
	
	/*Same size buttons*/
	.allpancakes_style_btn__11K3T {
		border: 1px solid #9FC4CE;
		background-color: #9FC4CE;
		color: #fff;
		font-size: 1em;
		cursor: pointer;
		text-decoration: none; 
		border-radius: 0px;
		-webkit-appearance: none;
		-moz-appearance: none;
        padding: .5em 2em;
        margin: 1em;
	}
	.allpancakes_style_btn__11K3T:hover {
		border: 1px solid #9FC4CE;
		background-color: #fff;
		color: #9FC4CE;
	}	
	button {
		border: none;
		box-shadow: none;
	}	
	
	/* Back to top */
	.allpancakes_style_back_to_top__9aQGY {
		color: #fff;
		background-color: #9FC4CE; /* TODO: change color */
		padding: 5px 10px;
		box-shadow: 2px 2px 10px #3f3f3f81;
		position: fixed;
		bottom: 6px;
		right: 15px;
		transition: 1s;
		z-index: 999;
	}



    
/********************************************

				Logo - brand

********************************************/


	.allpancakes_style_navigation_desktop__2xqOR .allpancakes_style_brand__oqPbq {
		width: 30%;
		justify-content: start;
		align-items: center;
	}
	.allpancakes_style_navigation_desktop__2xqOR .allpancakes_style_brand__oqPbq h1{
        color: #fff;
        font-family: 'Great Vibes', cursive;
        font-size: 1.5rem;
	}
    
/********************************************

				NAVIGATION

********************************************/

	.allpancakes_style_navigation_desktop__2xqOR {
        background: none;
        position: absolute;
		width: 100%;
	}
	.allpancakes_style_navigation_desktop__2xqOR .allpancakes_style_container__2DXM2 {
		height: 100px;
	}
	
	.allpancakes_style_navigation_desktop__2xqOR .allpancakes_style_nav__2lghh {
		width: 70%;
		display: flex;
		justify-content: flex-end;
		align-items: center;
	}
	.allpancakes_style_navigation_desktop__2xqOR .allpancakes_style_nav__2lghh>ul>li {
		padding: 10px 2em;
	}
	.allpancakes_style_navigation_desktop__2xqOR a:hover, .allpancakes_style_navigation_desktop__2xqOR .allpancakes_style_dropdown__Txp8T:hover>a {
		/* color: red; */
		text-decoration: underline;
	}
	.allpancakes_style_navigation_desktop__2xqOR .allpancakes_style_nav__2lghh>ul>li>a {
		text-transform: capitalize; /* TODO: depend */
		color: #fff;
	}
	.allpancakes_style_navigation_desktop__2xqOR .allpancakes_style_dropdown__Txp8T {
		overflow: hidden;
	}
	.allpancakes_style_arrow_up__FzNFr { /* for dropdown use */
        margin-left: 15px;
		position: absolute;
        width: 0; 
        height: 0; 
        border-left: 10px solid transparent;
        border-right: 10px solid transparent;
        border-bottom: 10px solid #fff;
        visibility: hidden;
        opacity: 0;
    }
	.allpancakes_style_navigation_desktop__2xqOR .allpancakes_style_dropdown_content__2zhvC {
		display: none;
		position: absolute;
		background-color: #f5f5f5;
		min-width: 140px;
		box-shadow: 0px 1px 1px 0px rgba(0,0,0,0.2);
		z-index: 10;
		margin-top: 10px;
	}
	.allpancakes_style_navigation_desktop__2xqOR .allpancakes_style_dropdown_content__2zhvC a {
		float: none;
		padding: 12px 16px;
		display: block;
		text-align: left;
	}
	.allpancakes_style_navigation_desktop__2xqOR .allpancakes_style_dropdown__Txp8T:hover > .allpancakes_style_dropdown_content__2zhvC {
		display: block;
	}
	.allpancakes_style_navigation_desktop__2xqOR .allpancakes_style_dropdown__Txp8T:hover > .allpancakes_style_arrow_up__FzNFr {
		visibility: visible;
		opacity: 1;
	}
	.allpancakes_style_navigation_desktop__2xqOR .allpancakes_style_nav__2lghh>ul>li>a.allpancakes_style_active__3d9ey {
		/* color: black; */
	}
	.allpancakes_style_navigation_desktop__2xqOR .allpancakes_style_menu_icon__34hQR {
		display: none;
		width: 25px;
	}





	/* Navigation Mobile */
	.allpancakes_style_navigation_mobile__2cnVG {
		position: fixed;
		top: -150%;
		width: 100%;
		z-index: 9999;
		background: #fff;
	}
	.allpancakes_style_navigation_mobile__2cnVG.allpancakes_style_animated__2VUnB{
		top: 0;
	}
	.allpancakes_style_navigation_mobile__2cnVG .allpancakes_style_wrapper__SmGsH {
		height: 100vh;
	}
	.allpancakes_style_navigation_mobile__2cnVG .allpancakes_style_flex__3uqdc {
		flex-direction: column;
		justify-content: center;
		align-items: center;
	}
	.allpancakes_style_navigation_mobile__2cnVG .allpancakes_style_btn_close__3HQ54 {
		position: absolute;
		top: 20px;
		right: 20px;
	}
	.allpancakes_style_navigation_mobile__2cnVG ul li{
		padding: 10px 0;
	}
	.allpancakes_style_navigation_mobile__2cnVG a,
	.allpancakes_style_navigation_mobile__2cnVG li.allpancakes_style_dropdown_mobile__EBoX6{
		font-size: 2rem;
	}
	.allpancakes_style_navigation_mobile__2cnVG .allpancakes_style_dropdown_content_mobile__Ufeif{
		display: none
	}

	




    
/********************************************

				COMMON CONTENT

********************************************/

	.allpancakes_style_common_content__6xLhO {
		height: 100%;
		padding: 50px 0;
	}
	.allpancakes_style_common_content__6xLhO h1 {
		/* color: #49a6d4; */
        font-size: 2rem;
		font-family: 'Great Vibes', cursive;
		padding: .5em;
	}
	.allpancakes_style_common_content__6xLhO .allpancakes_style_sub_headline__3cOkd {
		padding: 15px 0;
	}
	.allpancakes_style_common_content__6xLhO .allpancakes_style_copy__2Uy88 {
		margin: 25px 0;
	}
	.allpancakes_style_common_content__6xLhO .allpancakes_style_copy__2Uy88 p{
		font-size: 1rem;
		margin: .5em auto;
	}
	
	




/********************************************

				COMMON HERO

********************************************/


	.allpancakes_style_common_hero__2eMQw .allpancakes_style_hero_image__lPBGc {
		height: 350px;
		width: 100%;
		z-index: -1;
		background-size: cover;
		background-repeat: no-repeat;
		background-position: center;
	}
	.allpancakes_style_common_hero__2eMQw .allpancakes_style_hero_image__lPBGc.allpancakes_style_full_height__32-Ov {
		height: 100vh;
	}
	.allpancakes_style_common_hero__2eMQw .allpancakes_style_overlay__2e6Li {
	    background-color: rgba(0, 0, 0, 0.5);
		height: 100%;
	}
	.allpancakes_style_common_hero__2eMQw .allpancakes_style_content__SLytc {
		display: flex;
		justify-content: center;
		align-items: center;
		height: 100%;
		z-index: 10;
		color: #fff;
	}
	.allpancakes_style_common_hero__2eMQw .allpancakes_style_content__SLytc .allpancakes_style_copy__2Uy88 {
		color: #fff;
		text-align: center;
		font-family: "", sans-serif;
		font-style: normal;
	}
	.allpancakes_style_common_hero__2eMQw .allpancakes_style_container__2DXM2 .allpancakes_style_copy__2Uy88 .allpancakes_style_title__38y8j {
        font-family: 'Great Vibes', cursive;
		font-size: 5rem;
		font-weight: 400;
		padding: 25px;
		/* text-transform: uppercase; */
    }
    .allpancakes_style_common_hero__2eMQw .allpancakes_style_full_height__32-Ov .allpancakes_style_container__2DXM2 .allpancakes_style_copy__2Uy88 .allpancakes_style_title__38y8j {
		font-size: 12rem;
	}
	.allpancakes_style_common_hero__2eMQw .allpancakes_style_container__2DXM2 .allpancakes_style_copy__2Uy88 .allpancakes_style_subtitle__FJFCB {
        font-family: 'Great Vibes', cursive;
		font-size: 2rem;
	}









/********************************************

				SLIDER

********************************************/


	.allpancakes_style_swiper-slide__3vs2y {
		background-size: cover;
		background-repeat: no-repeat;
		height: 620px;
		width: 100%;
		background-position: center;
	}
	/* .slide .slide2 {
		background-position: center -120px;
	}
	.slide .slide3 {
		background-position: center -120px;
	} */

	.allpancakes_style_swiper-container__2mwzn {
		height: 620px;
		width: 100%;
		z-index: -1;
	}
	/* .swiper-button-prev{
		left: 20% !important;
		top: 82% !important;
		background-image: url("/img/prev-arrow.png") !important;
	}
	.swiper-button-prev:hover{
		left: 19.8% !important;
		transition: .25s !important;
	}
	.swiper-button-next{
		right: 20% !important;
		top: 82% !important;
		background-image: url("/img/next-arrow.png") !important;
	}
	.swiper-button-next:hover{
		right: 19.8% !important;
		transition: .25s !important;
	} */
	.allpancakes_style_swiper-pagination-bullet__1n1o9 {
		background: #fff !important;
	}
	.allpancakes_style_swiper-pagination-bullet-active__2Epu- {
		background: #007aff !important;
		
	}
	.allpancakes_style_swiper-container__2mwzn .allpancakes_style_overlay__2e6Li {
		background-color: rgba(0, 0, 0, 0.4);
		height: 100%;
	}/* 
	.swiper-slide:hover .overlay {
		background-color: rgba(0, 0, 0, 0.1);
		transition: .25s ease;
	} */
	.allpancakes_style_swiper-container__2mwzn .allpancakes_style_container__2DXM2 {
		position: relative;
		display: flex;
		justify-content: center;
		height: 90%;
	}
	.allpancakes_style_swiper-container__2mwzn .allpancakes_style_container__2DXM2 .allpancakes_style_copy__2Uy88 {
		align-self: flex-end;
		color: #fff;
		text-align: center;
		margin-left: 15px;
		font-family: "", sans-serif;
		font-style: normal;
	}
	.allpancakes_style_swiper-container__2mwzn .allpancakes_style_container__2DXM2 .allpancakes_style_copy__2Uy88 .allpancakes_style_title__38y8j {
		font-size: 3rem;
		font-weight: 600;
		padding: 10px;
		letter-spacing: 3px;
	}
	.allpancakes_style_swiper-container__2mwzn .allpancakes_style_container__2DXM2 .allpancakes_style_copy__2Uy88 .allpancakes_style_subtitle__FJFCB {
		font-size: 2.1rem;
		padding: 10px;
	}







/********************************************

				ANIMATIONS

********************************************/


/* @keyframes slide_down{
	0%{
		transform: translateY(-15%);
		opacity: 0;
		}
	100%{
		transform: rotate(xx) translateY(0);
		opacity: 1;
		}
}

.keyframes_slide_down{
    animation: slide_down .5s forwards 0s linear;
} */



/********************************************

				Image Box

********************************************/

.allpancakes_style_image_box__32VDn {
	width: 33.33%;
	padding: 3em;
	height: auto;
}
.allpancakes_style_image_box__32VDn .allpancakes_style_flex_container__hZnpF {
	flex-direction: column;
}
.allpancakes_style_image_box__32VDn img {
	height: auto;
}
.allpancakes_style_image_box__32VDn p {
	text-align: right;
	width: 100%;
	margin: 5px auto;
}
.allpancakes_style_image_box__32VDn .allpancakes_style_flex_row__3Aqdy {
	flex-direction: row;
}





.allpancakes_style_image_box_1s__19d16 {
	width: 100%;
	padding: 3em;
	height: auto;
}
.allpancakes_style_image_box_1s__19d16 .allpancakes_style_flex_container__hZnpF {
	width: 70%;
}


.allpancakes_style_image_box_2s__3eP3z {
	width: 100%;
	padding: 3em;
	height: auto;
}
.allpancakes_style_image_box_2s__3eP3z .allpancakes_style_flex_container__hZnpF {
	width: 90%;
}
.allpancakes_style_image_box_2s__3eP3z .allpancakes_style_flex_container__hZnpF >div {
	padding: 1em;
}







.allpancakes_style_image_box_with_copy__1QuTB {
	margin: 3em 0;
}
.allpancakes_style_image_box_with_copy__1QuTB .allpancakes_style_image__kcPI5 {
	width: 40%;
	min-width: 400px;
}
.allpancakes_style_image_box_with_copy__1QuTB .allpancakes_style_copy__2Uy88 {
	width: 60%;
	max-width: 500px;
    margin: 0 100px;
    padding: 2em 0em;
    border-bottom: 3px solid #ddd;
}

.allpancakes_style_image_box_with_copy__1QuTB .allpancakes_style_copy__2Uy88 h1{
	padding: 10px 0;
}
.allpancakes_style_image_box_with_copy__1QuTB p {
	width: 100%;
	margin: 5px auto;
}
.allpancakes_style_image_box_with_copy__1QuTB .allpancakes_style_flex_row__3Aqdy {
	flex-direction: row;
}






/********************************************

				Block 1

********************************************/

/* .block_1 {
    height: 500px;
}
.block_1 .container {
    position: relative;
    top: -100px;
} */
.allpancakes_style_block_1__3ynZT .allpancakes_style_container__2DXM2 .allpancakes_style_img_text__2PBL1 {
    width: 100%;
    background: #fff;
}
.allpancakes_style_block_1__3ynZT .allpancakes_style_container__2DXM2 .allpancakes_style_img_text__2PBL1 .allpancakes_style_img__xhgrq {
    width: 40%;
    height: 460px;
    background-size: cover;
    background-position: center;
}
.allpancakes_style_block_1__3ynZT .allpancakes_style_container__2DXM2 .allpancakes_style_img_text__2PBL1 .allpancakes_style_text__3w0v8 {
    width: 70%;
    height: 460px;
}
.allpancakes_style_block_1__3ynZT .allpancakes_style_img_text__2PBL1 .allpancakes_style_text__3w0v8 > div {
    padding: 0 3em;
}



/********************************************

				Block 2

********************************************/

.allpancakes_style_block_2__3_IbB .allpancakes_style_container__2DXM2 .allpancakes_style_img_text__2PBL1 {
    width: 100%;
    background: #fff;
}
.allpancakes_style_block_2__3_IbB .allpancakes_style_container__2DXM2 .allpancakes_style_img_text__2PBL1 .allpancakes_style_img__xhgrq {
    width: 25%;
    height: 300px;
    background-size: cover;
    background-position: center;
}
.allpancakes_style_block_2__3_IbB .allpancakes_style_container__2DXM2 .allpancakes_style_img_text__2PBL1 .allpancakes_style_text__3w0v8 {
    width: 25%;
    height: 300px;
}
.allpancakes_style_block_2__3_IbB h3 span {
	float: right;
}
.allpancakes_style_block_2__3_IbB h4 {
	padding: 2em 10em;
	font-weight: 400;
}
.allpancakes_style_block_2__3_IbB .allpancakes_style_menu__DJ_9S{
	justify-content: space-around;
}
.allpancakes_style_block_2__3_IbB .allpancakes_style_col__2-uUe{
	width: 50%;
}
.allpancakes_style_block_2__3_IbB .allpancakes_style_col__2-uUe > div{
	margin: 2em;
	border-bottom: 1px dashed;
    padding-bottom: 2em;
}
.allpancakes_style_block_2__3_IbB .allpancakes_style_col__2-uUe > div>p{
	font-style: italic;
}



.allpancakes_style_block_3__vxs0n .allpancakes_style_img__xhgrq{
    width: 100%;
    height: 502px;
    background-size: cover;
    background-position: center;
}
.allpancakes_style_block_3__vxs0n .allpancakes_style_copy__2Uy88 {
	width: 100%;
    text-align: center;
    padding: 2em;
}
.allpancakes_style_block_3__vxs0n .allpancakes_style_copy__2Uy88 h1 {
	font-size: 3rem;
}
.allpancakes_style_block_3__vxs0n .allpancakes_style_copy__2Uy88 p {
	padding: 1em 0;
}

.allpancakes_style_testimonial__2Xrvp .allpancakes_style_hero_image__lPBGc{
	height: 500px;
}
.allpancakes_style_testimonial__2Xrvp .allpancakes_style_container__2DXM2 .allpancakes_style_copy__2Uy88 h1 {
	font-size: 1.5rem;
	font-weight: 400;
}
.allpancakes_style_testimonial__2Xrvp .allpancakes_style_container__2DXM2 .allpancakes_style_copy__2Uy88 .allpancakes_style_title__38y8j {
	padding: .8em;
}

.allpancakes_style_block_4__7IoCO .allpancakes_style_flex_container__hZnpF {
	justify-content: space-between;
}
.allpancakes_style_block_4__7IoCO p{
	padding: 1em 10em;
}
.allpancakes_style_block_4__7IoCO input {
	width: 33%;
	background:#F2F2F2;
    padding: .8em 1em;
}
.allpancakes_style_block_4__7IoCO textarea {
	width: 100%;
	background: #F2F2F2;
	padding: .8em 1em;
	margin-top: .4em;
}

.allpancakes_style_footer__1Y8dj {
	color: #fff;
}
.allpancakes_style_footer__1Y8dj .allpancakes_style_flex_container__hZnpF{
	justify-content: space-between;
	align-items: flex-start;
}
.allpancakes_style_footer__1Y8dj .allpancakes_style_copy__2Uy88 {
	width: 33%;
	padding: 2em;
}

.allpancakes_style_footer__1Y8dj .allpancakes_style_container__2DXM2 .allpancakes_style_copy__2Uy88 .allpancakes_style_title__38y8j{
	font-size: 3rem;
	padding: 0;
	padding-bottom: 25px;
}

.allpancakes_style_footer__1Y8dj .allpancakes_style_copy__2Uy88 h2 {
	font-size: 1.5rem;
	font-weight: 400;
	padding-bottom: 25px;
}
.allpancakes_style_footer__1Y8dj .allpancakes_style_copy__2Uy88 p {
	line-height: 1.9;
	color: #ddd;
}
footer .allpancakes_style_copyright__1zTW0 {
	background: #000;
	color: #ddd;
}
footer .allpancakes_style_copyright__1zTW0 .allpancakes_style_flex_container__hZnpF {
	justify-content: space-between;
	height: 50px;
}






/********************************************











   RESPONSIVE CSS














   
********************************************/

/********************************************

GENERAL

********************************************/
@media only screen and (max-width: 992px) {  
    .allpancakes_style_container__2DXM2 {
        width: 90%;
    }
}
@media only screen and (max-width: 769px) {
    
    .allpancakes_style_flex__3uqdc {
        flex-direction: column;
    }
}

@media only screen and (max-width: 579px) {
    .allpancakes_style_navigation_desktop__2xqOR .allpancakes_style_brand__oqPbq {
        width: 35%;
        margin-top: 10px;
    }
    .allpancakes_style_navigation_desktop__2xqOR .allpancakes_style_menu_icon__34hQR {
        color: #fff;
        margin-top: 10px;
    }
    .allpancakes_style_common_hero__2eMQw .allpancakes_style_full_height__32-Ov .allpancakes_style_container__2DXM2 .allpancakes_style_copy__2Uy88 .allpancakes_style_title__38y8j {
        font-size: 6rem;
    }
    .allpancakes_style_common_hero__2eMQw .allpancakes_style_container__2DXM2 .allpancakes_style_copy__2Uy88 .allpancakes_style_subtitle__FJFCB {
        margin-left: 50px;
    }


    .allpancakes_style_block_1__3ynZT .allpancakes_style_container__2DXM2 .allpancakes_style_img_text__2PBL1 .allpancakes_style_img__xhgrq {
        display: none;
    }
    .allpancakes_style_block_1__3ynZT .allpancakes_style_container__2DXM2 .allpancakes_style_img_text__2PBL1 .allpancakes_style_text__3w0v8 {
        width: 100%;
        height: auto;
    }
    .allpancakes_style_block_1__3ynZT .allpancakes_style_img_text__2PBL1 .allpancakes_style_text__3w0v8 > div {
        padding: 0;
    }
    .allpancakes_style_common_content__6xLhO h1 {
        font-size: 1.8rem;
        padding: 1em 0;
    }

    .allpancakes_style_common_hero__2eMQw .allpancakes_style_container__2DXM2 .allpancakes_style_copy__2Uy88 .allpancakes_style_title__38y8j {
        font-size: 3rem;
    }
    .allpancakes_style_common_hero__2eMQw .allpancakes_style_hero_image__lPBGc {
        background-attachment: inherit !important;
    }

    .allpancakes_style_block_2__3_IbB h4 {
        padding: 1em 0em;
    }
    .allpancakes_style_block_2__3_IbB .allpancakes_style_col__2-uUe {
        width: 100%;
    }
    .allpancakes_style_block_2__3_IbB .allpancakes_style_col__2-uUe > div {
        margin: 2em 0;
    }
    .allpancakes_style_block_3__vxs0n .allpancakes_style_img__xhgrq {
        height: 240px;
    }
    .allpancakes_style_testimonial__2Xrvp .allpancakes_style_container__2DXM2 .allpancakes_style_copy__2Uy88 .allpancakes_style_title__38y8j {
        font-size: 2.5rem !important;
    }

    .allpancakes_style_block_4__7IoCO p {
        padding: 1em 0em;
    }
    .allpancakes_style_block_4__7IoCO input {
        width: 100%;
        background: #F2F2F2;
        padding: .8em 1em;
        margin: 5px;
    }
    .allpancakes_style_footer__1Y8dj .allpancakes_style_hero_image__lPBGc {
        height: auto;
    }
    .allpancakes_style_footer__1Y8dj .allpancakes_style_copy__2Uy88 {
        width: 100%;
        padding: 3em 0 0;
    }
    footer .allpancakes_style_copyright__1zTW0 .allpancakes_style_flex_container__hZnpF {
        justify-content: space-around;
        height: 100px;
    }
    footer .allpancakes_style_copyright__1zTW0 .allpancakes_style_flex_container__hZnpF p {
        margin-top: 15px;
    }
    footer .allpancakes_style_copyright__1zTW0 ul.allpancakes_style_flex_container__hZnpF {
        justify-content: space-between;
        flex-direction: row;
    }

}



/********************************************

NAVIGATION BAR

********************************************/
@media only screen and (max-width: 992px) {
}


@media only screen and (max-width: 769px) {
    .allpancakes_style_navigation_desktop__2xqOR .allpancakes_style_nav__2lghh {
        display: none;
    }
    .allpancakes_style_navigation_desktop__2xqOR .allpancakes_style_container__2DXM2.allpancakes_style_flex__3uqdc {
        flex-direction: row;
        justify-content: space-between;
        padding: 10px 0;
    }
    .allpancakes_style_navigation_desktop__2xqOR .allpancakes_style_menu_icon__34hQR {
        display: flex;
    }



    
    /* =========================== */
    /* =======  Navigation - Mobile =======*/
    /* ========================== */

    .allpancakes_style_navigation_desktop__2xqOR .allpancakes_style_hamburger__PL7LN {
        display: flex;
    }
    .allpancakes_style_navigation_mobile__2cnVG .allpancakes_style_flex__3uqdc {
		flex-direction: column;
		justify-content: center;
		align-items: center;
    }
    
    .allpancakes_style_image_box__32VDn {
        width: 100%;
    }

}



/********************************************

        IMAGE BOX

********************************************/
@media only screen and (max-width: 992px) {

    .allpancakes_style_image_box_with_copy__1QuTB .allpancakes_style_image__kcPI5 {
        min-width: 300px;
    }
    .allpancakes_style_image_box_with_copy__1QuTB .allpancakes_style_copy__2Uy88 {
        width: 60%;
        max-width: 600px;
        margin: 0 35px;
        padding: 2em 0em;
    }
}


@media only screen and (max-width: 769px) {
    .allpancakes_style_flex_container__hZnpF {
        flex-direction: column;
    }
    .allpancakes_style_image_box__32VDn,
    .allpancakes_style_image_box_with_copy__1QuTB {
        width: 100%;
        padding: 1em;
        margin: 1em 0;
    }
    .allpancakes_style_image_box_1s__19d16,
    .allpancakes_style_image_box_2s__3eP3z {
        width: 100%;
        padding: 0;
        margin: 0;
    }
    .allpancakes_style_image_box_1s__19d16 .allpancakes_style_flex_container__hZnpF,
    .allpancakes_style_image_box_2s__3eP3z .allpancakes_style_flex_container__hZnpF {
        width: 100%;
        padding: 0;
        margin: 0;
    }
    .allpancakes_style_image_box_1s__19d16 .allpancakes_style_flex_container__hZnpF>div,
    .allpancakes_style_image_box_2s__3eP3z .allpancakes_style_flex_container__hZnpF>div {
        padding: 1em 0 0;
        margin: 0;
    }

    .allpancakes_style_image_box_with_copy__1QuTB .allpancakes_style_image__kcPI5 {
        min-width: 100%;
        width: 100%;
    }
    .allpancakes_style_image_box_with_copy__1QuTB .allpancakes_style_copy__2Uy88 {
        width: 100%;
        max-width: 100%;
        margin: 0 35px;
        padding: 1em 0em;
    }

}



