/********************************************

GENERAL 

********************************************/
@import "//netdna.bootstrapcdn.com/font-awesome/3.0/css/font-awesome.css"; /* this is need for input search glass icon */
	*{
		margin: 0;
		padding: 0;
		box-sizing: border-box;
		-webkit-box-sizing: border-box;
		transition: all .25s;
	}
	.body_wrapper, .body_wrapper input {
        overflow-x:hidden;
		font-family: "",sans-serif; /* TODO: update new fonts */
		font-style: normal;
		font-weight: 100;
		color: #707070; /* TODO: update new color */
		-moz-osx-font-smoothing: grayscale;
		background: #fff;
		font-size: 16px;
		line-height: 1.5rem;
		letter-spacing: 0;
		vertical-align: baseline;
	}
    .common_color {
        color: #49a6d4;
        color: #707070;
		color: #f5f5f5;
		color: #9FC4CE;
    }
	.body_wrapper a {
		text-decoration: none;
		color: inherit;
		cursor: pointer;
	}
	
    .body_wrapper li {
		list-style: none;
		margin-left: 30px;
	}
	.body_wrapper ul.list_style_initial li {
		list-style-type: initial;
	}
	.body_wrapper img {
		max-width: 100%;
	}
	.clearfix:after {
		display: block;
		content: '';
		clear:both;
	}
	.wrapper {
		width: 100%;
	}
	.container {
		width: 90%;
		margin: auto;
        max-width: 1240px;
	}
	.flex {
		display: flex;
    }
	.flex_container {
		display: flex;
		justify-content: center;
		align-items: center;
	}
	.flex_wrap {
		display: -webkit-flex; /* Safari */
		-webkit-flex-wrap: wrap; /* Safari 6.1+ */
		display: flex;   
		flex-wrap: wrap;
	}
    .relative {
        position: relative;
    }
    .absolute {
        position: absolute;
    }
	.hidden {
        visibility: hidden;
		opacity: 0;
		transition: visibility 0s, opacity .25s ease;
	}
    .display_none {
        display: none;
    }
	.bg_light_grey {
        background-color: #f5f5f5;
	}
	.bold_600{
        font-weight: 600;
    }
    .bold_800 {
        font-weight: 800;
    }
    .bold {
        font-weight: bold;
    }
	.text_align_center {
        text-align: center;
    }
    .text_align_left{
        text-align: left;
    }
    .text_align_right{
        text-align: right;
    }
    .m_auto{
        margin: auto;
    }
    
	.recaptcha_notice {
        /* font-size: .7rem; */
	}
	.grecaptcha-badge {
        /* display: none ; */
	}
    
    
    
    
    
    

/*************************


		TABLE 


*************************/
	.table-responsive{
		overflow-x: auto;
	}

	table {
		border-collapse: collapse;
		width: 75%;
		border: none;
		margin: 25px auto;
	}
	th, td {
		text-align: left;
		color: #707070;
		/* border: 1px solid #49a6d4; */
		text-align: center; 
		vertical-align: middle;
		width: 150px;
		height: 115px;
		word-wrap: normal;
		padding: 30px;
		font-weight: 600;
	}
	th{
		/* color: #49a6d4; */
		text-transform: uppercase;
	}
	th:last-child, td:last-child {
		border-right: none;
	}
	tr:first-child th {
		border-top: none;
	}
	tr:last-child td {
		border-bottom: none;
	}
	.table-head { /* custom table head: create a span table head. */
		/* color: white; */
		text-align: center;
		background-color: #f5f5f5; /* TODO: update color */
		height: 35px;
		padding: 10px
    }
    




     
/*************************


		MODAL 


*************************/

	/***** Normal Form *****/
	
	.modal {
		display: none;
		position: fixed;
		bottom: 0;
		width: 100%;
		height: 100%;
		color: white;
		background-color: rgba(146, 146, 146, 0.384);
		z-index: 100;
		box-sizing: border-box;
		padding: 15px 0;
		overflow-y: auto;
		overflow-x: hidden;
	}

	.modal.flex {
		justify-content: center;
		align-items: center;
	}
	.modal .modal_content {
		background-color: #fff;
		padding: 5em;
		color: #49a6d4;
		position: relative;
		text-align: center;
		font-size: 1.2rem;
	}
	.modal .modal_content h2 {
		font-size: 1.7rem;
		padding-bottom: .5em;
	}
	.modal .modal_content h4{
		font-size: 1.2rem;
	}
	.modal .btn_modal_close {
		position: absolute;
		top: 5px;
		right: 12px;
		font-size: 1.5rem;
		font-weight: 600;
		color: #49a6d4;
		cursor: pointer;
	}





/*************************


		BUTTONS 


*************************/
	
	/*Same size buttons*/
	.btn {
		border: 1px solid #9FC4CE;
		background-color: #9FC4CE;
		color: #fff;
		font-size: 1em;
		cursor: pointer;
		text-decoration: none; 
		border-radius: 0px;
		-webkit-appearance: none;
		-moz-appearance: none;
        padding: .5em 2em;
        margin: 1em;
	}
	.btn:hover {
		border: 1px solid #9FC4CE;
		background-color: #fff;
		color: #9FC4CE;
	}	
	button {
		border: none;
		box-shadow: none;
	}	
	
	/* Back to top */
	.back_to_top {
		color: #fff;
		background-color: #9FC4CE; /* TODO: change color */
		padding: 5px 10px;
		box-shadow: 2px 2px 10px #3f3f3f81;
		position: fixed;
		bottom: 6px;
		right: 15px;
		transition: 1s;
		z-index: 999;
	}



    
/********************************************

				Logo - brand

********************************************/


	.navigation_desktop .brand {
		width: 30%;
		justify-content: start;
		align-items: center;
	}
	.navigation_desktop .brand h1{
        color: #fff;
        font-family: 'Great Vibes', cursive;
        font-size: 1.5rem;
	}
    
/********************************************

				NAVIGATION

********************************************/

	.navigation_desktop {
        background: none;
        position: absolute;
		width: 100%;
	}
	.navigation_desktop .container {
		height: 100px;
	}
	
	.navigation_desktop .nav {
		width: 70%;
		display: flex;
		justify-content: flex-end;
		align-items: center;
	}
	.navigation_desktop .nav>ul>li {
		padding: 10px 2em;
	}
	.navigation_desktop a:hover, .navigation_desktop .dropdown:hover>a {
		/* color: red; */
		text-decoration: underline;
	}
	.navigation_desktop .nav>ul>li>a {
		text-transform: capitalize; /* TODO: depend */
		color: #fff;
	}
	.navigation_desktop .dropdown {
		overflow: hidden;
	}
	.arrow_up { /* for dropdown use */
        margin-left: 15px;
		position: absolute;
        width: 0; 
        height: 0; 
        border-left: 10px solid transparent;
        border-right: 10px solid transparent;
        border-bottom: 10px solid #fff;
        visibility: hidden;
        opacity: 0;
    }
	.navigation_desktop .dropdown_content {
		display: none;
		position: absolute;
		background-color: #f5f5f5;
		min-width: 140px;
		box-shadow: 0px 1px 1px 0px rgba(0,0,0,0.2);
		z-index: 10;
		margin-top: 10px;
	}
	.navigation_desktop .dropdown_content a {
		float: none;
		padding: 12px 16px;
		display: block;
		text-align: left;
	}
	.navigation_desktop .dropdown:hover > .dropdown_content {
		display: block;
	}
	.navigation_desktop .dropdown:hover > .arrow_up {
		visibility: visible;
		opacity: 1;
	}
	.navigation_desktop .nav>ul>li>a.active {
		/* color: black; */
	}
	.navigation_desktop .menu_icon {
		display: none;
		width: 25px;
	}





	/* Navigation Mobile */
	.navigation_mobile {
		position: fixed;
		top: -150%;
		width: 100%;
		z-index: 9999;
		background: #fff;
	}
	.navigation_mobile.animated{
		top: 0;
	}
	.navigation_mobile .wrapper {
		height: 100vh;
	}
	.navigation_mobile .flex {
		flex-direction: column;
		justify-content: center;
		align-items: center;
	}
	.navigation_mobile .btn_close {
		position: absolute;
		top: 20px;
		right: 20px;
	}
	.navigation_mobile ul li{
		padding: 10px 0;
	}
	.navigation_mobile a,
	.navigation_mobile li.dropdown_mobile{
		font-size: 2rem;
	}
	.navigation_mobile .dropdown_content_mobile{
		display: none
	}

	




    
/********************************************

				COMMON CONTENT

********************************************/

	.common_content {
		height: 100%;
		padding: 50px 0;
	}
	.common_content h1 {
		/* color: #49a6d4; */
        font-size: 2rem;
		font-family: 'Great Vibes', cursive;
		padding: .5em;
	}
	.common_content .sub_headline {
		padding: 15px 0;
	}
	.common_content .copy {
		margin: 25px 0;
	}
	.common_content .copy p{
		font-size: 1rem;
		margin: .5em auto;
	}
	
	




/********************************************

				COMMON HERO

********************************************/


	.common_hero .hero_image {
		height: 350px;
		width: 100%;
		z-index: -1;
		background-size: cover;
		background-repeat: no-repeat;
		background-position: center;
	}
	.common_hero .hero_image.full_height {
		height: 100vh;
	}
	.common_hero .overlay {
	    background-color: rgba(0, 0, 0, 0.5);
		height: 100%;
	}
	.common_hero .content {
		display: flex;
		justify-content: center;
		align-items: center;
		height: 100%;
		z-index: 10;
		color: #fff;
	}
	.common_hero .content .copy {
		color: #fff;
		text-align: center;
		font-family: "", sans-serif;
		font-style: normal;
	}
	.common_hero .container .copy .title {
        font-family: 'Great Vibes', cursive;
		font-size: 5rem;
		font-weight: 400;
		padding: 25px;
		/* text-transform: uppercase; */
    }
    .common_hero .full_height .container .copy .title {
		font-size: 12rem;
	}
	.common_hero .container .copy .subtitle {
        font-family: 'Great Vibes', cursive;
		font-size: 2rem;
	}









/********************************************

				SLIDER

********************************************/


	.swiper-slide {
		background-size: cover;
		background-repeat: no-repeat;
		height: 620px;
		width: 100%;
		background-position: center;
	}
	/* .slide .slide2 {
		background-position: center -120px;
	}
	.slide .slide3 {
		background-position: center -120px;
	} */

	.swiper-container {
		height: 620px;
		width: 100%;
		z-index: -1;
	}
	/* .swiper-button-prev{
		left: 20% !important;
		top: 82% !important;
		background-image: url("/img/prev-arrow.png") !important;
	}
	.swiper-button-prev:hover{
		left: 19.8% !important;
		transition: .25s !important;
	}
	.swiper-button-next{
		right: 20% !important;
		top: 82% !important;
		background-image: url("/img/next-arrow.png") !important;
	}
	.swiper-button-next:hover{
		right: 19.8% !important;
		transition: .25s !important;
	} */
	.swiper-pagination-bullet {
		background: #fff !important;
	}
	.swiper-pagination-bullet-active {
		background: #007aff !important;
		
	}
	.swiper-container .overlay {
		background-color: rgba(0, 0, 0, 0.4);
		height: 100%;
	}/* 
	.swiper-slide:hover .overlay {
		background-color: rgba(0, 0, 0, 0.1);
		transition: .25s ease;
	} */
	.swiper-container .container {
		position: relative;
		display: flex;
		justify-content: center;
		height: 90%;
	}
	.swiper-container .container .copy {
		align-self: flex-end;
		color: #fff;
		text-align: center;
		margin-left: 15px;
		font-family: "", sans-serif;
		font-style: normal;
	}
	.swiper-container .container .copy .title {
		font-size: 3rem;
		font-weight: 600;
		padding: 10px;
		letter-spacing: 3px;
	}
	.swiper-container .container .copy .subtitle {
		font-size: 2.1rem;
		padding: 10px;
	}







/********************************************

				ANIMATIONS

********************************************/


/* @keyframes slide_down{
	0%{
		transform: translateY(-15%);
		opacity: 0;
		}
	100%{
		transform: rotate(xx) translateY(0);
		opacity: 1;
		}
}

.keyframes_slide_down{
    animation: slide_down .5s forwards 0s linear;
} */



/********************************************

				Image Box

********************************************/

.image_box {
	width: 33.33%;
	padding: 3em;
	height: auto;
}
.image_box .flex_container {
	flex-direction: column;
}
.image_box img {
	height: auto;
}
.image_box p {
	text-align: right;
	width: 100%;
	margin: 5px auto;
}
.image_box .flex_row {
	flex-direction: row;
}





.image_box_1s {
	width: 100%;
	padding: 3em;
	height: auto;
}
.image_box_1s .flex_container {
	width: 70%;
}


.image_box_2s {
	width: 100%;
	padding: 3em;
	height: auto;
}
.image_box_2s .flex_container {
	width: 90%;
}
.image_box_2s .flex_container >div {
	padding: 1em;
}







.image_box_with_copy {
	margin: 3em 0;
}
.image_box_with_copy .image {
	width: 40%;
	min-width: 400px;
}
.image_box_with_copy .copy {
	width: 60%;
	max-width: 500px;
    margin: 0 100px;
    padding: 2em 0em;
    border-bottom: 3px solid #ddd;
}

.image_box_with_copy .copy h1{
	padding: 10px 0;
}
.image_box_with_copy p {
	width: 100%;
	margin: 5px auto;
}
.image_box_with_copy .flex_row {
	flex-direction: row;
}






/********************************************

				Block 1

********************************************/

/* .block_1 {
    height: 500px;
}
.block_1 .container {
    position: relative;
    top: -100px;
} */
.block_1 .container .img_text {
    width: 100%;
    background: #fff;
}
.block_1 .container .img_text .img {
    width: 40%;
    height: 460px;
    background-size: cover;
    background-position: center;
}
.block_1 .container .img_text .text {
    width: 70%;
    height: 460px;
}
.block_1 .img_text .text > div {
    padding: 0 3em;
}



/********************************************

				Block 2

********************************************/

.block_2 .container .img_text {
    width: 100%;
    background: #fff;
}
.block_2 .container .img_text .img {
    width: 25%;
    height: 300px;
    background-size: cover;
    background-position: center;
}
.block_2 .container .img_text .text {
    width: 25%;
    height: 300px;
}
.block_2 h3 span {
	float: right;
}
.block_2 h4 {
	padding: 2em 10em;
	font-weight: 400;
}
.block_2 .menu{
	justify-content: space-around;
}
.block_2 .col{
	width: 50%;
}
.block_2 .col > div{
	margin: 2em;
	border-bottom: 1px dashed;
    padding-bottom: 2em;
}
.block_2 .col > div>p{
	font-style: italic;
}



.block_3 .img{
    width: 100%;
    height: 502px;
    background-size: cover;
    background-position: center;
}
.block_3 .copy {
	width: 100%;
    text-align: center;
    padding: 2em;
}
.block_3 .copy h1 {
	font-size: 3rem;
}
.block_3 .copy p {
	padding: 1em 0;
}

.testimonial .hero_image{
	height: 500px;
}
.testimonial .container .copy h1 {
	font-size: 1.5rem;
	font-weight: 400;
}
.testimonial .container .copy .title {
	padding: .8em;
}

.block_4 .flex_container {
	justify-content: space-between;
}
.block_4 p{
	padding: 1em 10em;
}
.block_4 input {
	width: 33%;
	background:#F2F2F2;
    padding: .8em 1em;
}
.block_4 textarea {
	width: 100%;
	background: #F2F2F2;
	padding: .8em 1em;
	margin-top: .4em;
}

.footer {
	color: #fff;
}
.footer .flex_container{
	justify-content: space-between;
	align-items: flex-start;
}
.footer .copy {
	width: 33%;
	padding: 2em;
}

.footer .container .copy .title{
	font-size: 3rem;
	padding: 0;
	padding-bottom: 25px;
}

.footer .copy h2 {
	font-size: 1.5rem;
	font-weight: 400;
	padding-bottom: 25px;
}
.footer .copy p {
	line-height: 1.9;
	color: #ddd;
}
footer .copyright {
	background: #000;
	color: #ddd;
}
footer .copyright .flex_container {
	justify-content: space-between;
	height: 50px;
}






/********************************************











   RESPONSIVE CSS














   
********************************************/

/********************************************

GENERAL

********************************************/
@media only screen and (max-width: 992px) {  
    .container {
        width: 90%;
    }
}
@media only screen and (max-width: 769px) {
    
    .flex {
        flex-direction: column;
    }
}

@media only screen and (max-width: 579px) {
    .navigation_desktop .brand {
        width: 35%;
        margin-top: 10px;
    }
    .navigation_desktop .menu_icon {
        color: #fff;
        margin-top: 10px;
    }
    .common_hero .full_height .container .copy .title {
        font-size: 6rem;
    }
    .common_hero .container .copy .subtitle {
        margin-left: 50px;
    }


    .block_1 .container .img_text .img {
        display: none;
    }
    .block_1 .container .img_text .text {
        width: 100%;
        height: auto;
    }
    .block_1 .img_text .text > div {
        padding: 0;
    }
    .common_content h1 {
        font-size: 1.8rem;
        padding: 1em 0;
    }

    .common_hero .container .copy .title {
        font-size: 3rem;
    }
    .common_hero .hero_image {
        background-attachment: inherit !important;
    }

    .block_2 h4 {
        padding: 1em 0em;
    }
    .block_2 .col {
        width: 100%;
    }
    .block_2 .col > div {
        margin: 2em 0;
    }
    .block_3 .img {
        height: 240px;
    }
    .testimonial .container .copy .title {
        font-size: 2.5rem !important;
    }

    .block_4 p {
        padding: 1em 0em;
    }
    .block_4 input {
        width: 100%;
        background: #F2F2F2;
        padding: .8em 1em;
        margin: 5px;
    }
    .footer .hero_image {
        height: auto;
    }
    .footer .copy {
        width: 100%;
        padding: 3em 0 0;
    }
    footer .copyright .flex_container {
        justify-content: space-around;
        height: 100px;
    }
    footer .copyright .flex_container p {
        margin-top: 15px;
    }
    footer .copyright ul.flex_container {
        justify-content: space-between;
        flex-direction: row;
    }

}



/********************************************

NAVIGATION BAR

********************************************/
@media only screen and (max-width: 992px) {
}


@media only screen and (max-width: 769px) {
    .navigation_desktop .nav {
        display: none;
    }
    .navigation_desktop .container.flex {
        flex-direction: row;
        justify-content: space-between;
        padding: 10px 0;
    }
    .navigation_desktop .menu_icon {
        display: flex;
    }



    
    /* =========================== */
    /* =======  Navigation - Mobile =======*/
    /* ========================== */

    .navigation_desktop .hamburger {
        display: flex;
    }
    .navigation_mobile .flex {
		flex-direction: column;
		justify-content: center;
		align-items: center;
    }
    
    .image_box {
        width: 100%;
    }

}



/********************************************

        IMAGE BOX

********************************************/
@media only screen and (max-width: 992px) {

    .image_box_with_copy .image {
        min-width: 300px;
    }
    .image_box_with_copy .copy {
        width: 60%;
        max-width: 600px;
        margin: 0 35px;
        padding: 2em 0em;
    }
}


@media only screen and (max-width: 769px) {
    .flex_container {
        flex-direction: column;
    }
    .image_box,
    .image_box_with_copy {
        width: 100%;
        padding: 1em;
        margin: 1em 0;
    }
    .image_box_1s,
    .image_box_2s {
        width: 100%;
        padding: 0;
        margin: 0;
    }
    .image_box_1s .flex_container,
    .image_box_2s .flex_container {
        width: 100%;
        padding: 0;
        margin: 0;
    }
    .image_box_1s .flex_container>div,
    .image_box_2s .flex_container>div {
        padding: 1em 0 0;
        margin: 0;
    }

    .image_box_with_copy .image {
        min-width: 100%;
        width: 100%;
    }
    .image_box_with_copy .copy {
        width: 100%;
        max-width: 100%;
        margin: 0 35px;
        padding: 1em 0em;
    }

}


